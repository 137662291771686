import './assets/scss/index.scss';
import '@aws-amplify/ui/dist/style.css';

import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  withAuthenticator,
} from 'aws-amplify-react';
import React, { Component } from 'react';

import { Amplify } from 'aws-amplify';
import { ConfirmProvider } from 'material-ui-confirm';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import theme from './theme';
import validate from 'validate.js';
import validators from './common/validators';

const { REACT_APP_IDENTITY_POOL_ID, REACT_APP_REGION, REACT_APP_USER_POOL_ID, REACT_APP_USER_POOL_WEB_CLIENT_ID } = process.env;

Amplify.configure({
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const browserHistory = createBrowserHistory();
const AuthTheme = {
  sectionHeader: {
    fontFamily: 'sans-serif',
  },
  inputLabel: {
    fontFamily: 'sans-serif',
  },
  input: {
    fontFamily: 'sans-serif',
  },
  hint: {
    fontFamily: 'sans-serif',
  },
  toast: {
    fontFamily: 'sans-serif',
  },
  sectionFooter: {
    fontFamily: 'sans-serif',
  },
  a: {
    fontFamily: 'sans-serif',
    color: '#ff5845',
  },
  button: {
    backgroundColor: '#ff5845',
    borderColor: 'red',
    fontWeight: 800,
    fontFamily: 'sans-serif',
  },
};

const signUpConfig = {
  header: 'Trade Portal Sign Up',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'First Name',
      key: 'given_name',
      type: 'string',
      displayOrder: 1,
      placeholder: 'First Name',
      hint: 'Enter Your First Name',
      required: true,
    },
    {
      label: 'Surname',
      key: 'family_name',
      type: 'string',
      displayOrder: 2,
      placeholder: 'Surname',
      hint: 'Enter Your Surname',
      required: true,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'string',
      displayOrder: 3,
      placeholder: 'Email',
      hint: 'Enter Your Email',
      required: true,
    },
    {
      label: 'Password',
      key: 'password',
      type: 'password',
      displayOrder: 4,
      placeholder: 'Enter Your Password',
      hint: 'Password',
      required: true,
    },
  ],
};

validate.validators = {
  ...validate.validators,
  ...validators,
};

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ConfirmProvider>
      </ThemeProvider>
    );
  }
}

export default withAuthenticator(App, {
  usernameAttributes: 'email',
  theme: AuthTheme,
  authenticatorComponents: [
    // <SignUp signUpConfig={signUpConfig} />,
    <SignIn />,
    <ConfirmSignIn />,
    <ConfirmSignUp />,
    <RequireNewPassword />,
    <ForgotPassword />,
  ],
});
